import { useAppStore } from '@/store/modules/app';
import { usePermissionStore } from '@/store/modules/permission';
import { storeToRefs } from 'pinia';
const LoginManage = defineAsyncComponent(() => import('./base/loginManage/index.vue'));
const PayGoodsManage = defineAsyncComponent(() => import('./base/payManage/index.vue'));
const ShareSetting = defineAsyncComponent(() => import('./base/shareSetting/index.vue'));
const MiniPay = defineAsyncComponent(() => import('./base/miniPay/index.vue'));
const Agreement = defineAsyncComponent(() => import('./common/agreement.vue'));
const Drogue = defineAsyncComponent(() => import('./common/drogue/index.vue'));
const Forbidden = defineAsyncComponent(() => import('./common/forbidden.vue'));
const ForceUpdate = defineAsyncComponent(() => import('./common/forceUpdate.vue'));
const RealName = defineAsyncComponent(() => import('./internal/realName.vue'));
const MessageManage = defineAsyncComponent(() => import('./internal/messageManage.vue'));
const VoucherManage = defineAsyncComponent(() => import('./internal/voucherManage.vue'));
const HelpCustom = defineAsyncComponent(() => import('./internal/helpCustom.vue'));
const ExchangeCodeSwitch = defineAsyncComponent(() => import('./internal/exchangeCodeSwitch.vue'));
const PagesManage = defineAsyncComponent(() => import('./global/pages.vue'));
const AgreementManage = defineAsyncComponent(() => import('./global/agreement.vue'));
const LoginWhite = defineAsyncComponent(() => import('./global/loginWhite.vue'));
const OrderSearch = defineAsyncComponent(() => import('./lookup/orderSearch/index.vue'));
const AccountSearch = defineAsyncComponent(() => import('./lookup/accountSearch/index.vue'));
const RefundSearch = defineAsyncComponent(() => import('./lookup/refundSearch/index.vue'));
const officialSubPackage = defineAsyncComponent(() => import('./officialPackage/officialSubPackage/index.vue'));
const ChannelManage = defineAsyncComponent(() => import('./officialPackage/channelManage/index.vue'));
const OfficialPackageManage = defineAsyncComponent(() => import('./officialPackage/officialPackageManage/index.vue'));
const GSBind = defineAsyncComponent(() => import('./playerOp/gsBind/index.vue'));
const EmailManage = defineAsyncComponent(() => import('./emailManage/index.vue'));
const H5Manage = defineAsyncComponent(() => import('./common/h5Manage/index.vue'));
const AccountService = defineAsyncComponent(() => import('./searchTools/accountService/index.vue'));
export const disabledInternal = ['fuxiu']; //internalChannel
export const UseToolTabs = () => {
  const {
    btnPermissions
  } = usePermissionStore();
  const {
    gameType
  } = storeToRefs(useAppStore());
  const currentTool = ref('base');
  const toolTabs = [
  // { label: '全部', value: 'all', },
  {
    label: '登录支付配置',
    value: 'base',
    role: 'login&pay',
    items: [{
      title: '登录管理',
      name: 'loginManage',
      imgSrc: require('./images/login.png'),
      component: shallowRef(LoginManage),
      desc: '配置登录方式',
      role: 'login'
    }, {
      title: '支付和商品管理',
      name: 'payManage',
      imgSrc: require('./images/pay.png'),
      component: shallowRef(PayGoodsManage),
      desc: '配置支付方式与商品关联',
      role: 'pay'
    }, {
      title: '分享设置',
      name: 'shareSetting',
      imgSrc: require('./images/share.png'),
      component: shallowRef(ShareSetting),
      desc: '渠道国内、全球分享设置',
      role: 'share'
    }]
  }, {
    label: '通用协议配置',
    value: 'common',
    role: 'agreement&drogue',
    items: [{
      title: '通用协议管理',
      name: 'agreement',
      imgSrc: require('./images/xygl.png'),
      component: shallowRef(Agreement),
      desc: '渠道协议、权限及三方SDK的说明配置',
      role: 'agreement'
    }, {
      title: '强更管理',
      name: 'forceUpdate',
      imgSrc: require('./images/qggl.png'),
      component: shallowRef(ForceUpdate),
      desc: '对游戏版本进行线上更新',
      role: 'forceUpdate'
    }, {
      title: '禁用管理',
      name: 'forbidden',
      imgSrc: require('./images/jygl.png'),
      component: shallowRef(Forbidden),
      desc: '对渠道玩家禁用新增、登录及支付功能',
      role: 'forbidden'
    }, {
      title: '内嵌H5管理',
      name: 'h5Manage',
      imgSrc: require('./images/h5.png'),
      component: shallowRef(H5Manage),
      desc: '在游戏内嵌入H5页面满足运营活动',
      role: 'h5ManageRole'
    }]
  }, {
    label: '官方国内渠道配置',
    value: 'internal',
    role: 'internalChannel',
    items: [{
      title: '实名管理',
      name: 'realName',
      imgSrc: require('./images/smgl.png'),
      component: shallowRef(RealName),
      desc: '官方实名控制与未成年防沉迷',
      role: 'internalRealName'
    }, {
      title: '短信文案',
      name: 'msgModel',
      imgSrc: require('./images/message_word.png'),
      component: shallowRef(MessageManage),
      desc: '登录、绑定及找回密码的短信内容',
      role: 'internalMsg'
    }, {
      title: '浮标管理',
      name: 'drogue',
      imgSrc: require('./images/fbgl.png'),
      component: shallowRef(Drogue),
      // desc: '配置浮标中个人中心、礼包、客服等功能',
      desc: '配置浮标中个人中心功能',
      role: 'drogue'
    }, {
      title: '代金券管理',
      name: 'voucher',
      imgSrc: require('./images/djqgl.png'),
      component: shallowRef(VoucherManage),
      desc: '代金券配置、发放与消耗状态',
      role: 'internalVoucher'
    }, {
      title: '“遇到问题”管理',
      name: 'help',
      imgSrc: require('./images/ydwtgl.png'),
      component: shallowRef(HelpCustom),
      desc: '游戏内帮助入口点击后的展示',
      role: 'internalHelp'
    }, {
      title: '兑换码开关',
      name: 'code',
      imgSrc: require('./images/code.png'),
      component: shallowRef(ExchangeCodeSwitch),
      desc: '选择是否开启iOS平台兑换码的使用',
      role: 'internalRedeemCode'
    }]
  }, {
    label: '官方全球渠道配置',
    value: 'global',
    role: 'globalChannel',
    items: [{
      title: '页面管理',
      name: 'pagesManage',
      imgSrc: require('./images/page.png'),
      component: shallowRef(PagesManage),
      desc: '配置帮助中心、个人中心等页面内容',
      role: 'globalPages'
    }, {
      title: '全球协议管理',
      name: 'globalAgreement',
      imgSrc: require('./images/xygl.png'),
      component: shallowRef(AgreementManage),
      desc: '全球协议开关及多语言配置',
      role: 'globalAgreement'
    }, {
      title: '登录黑白名单',
      name: 'whiteList',
      imgSrc: require('./images/dlmd.png'),
      component: shallowRef(LoginWhite),
      desc: '设置游戏登录地区限制及黑白名单',
      role: 'globalLoginWhite'
    }, {
      title: '邮箱管理',
      name: 'emailManage',
      imgSrc: require('./images/message_word.png'),
      component: shallowRef(EmailManage),
      desc: '账号中心里“安全邮箱入口”的配置，保护玩家账号安全。',
      role: 'emailManageRole'
    }]
  }, {
    label: '玩家账号订单管理',
    value: 'search',
    role: 'account&order',
    items: [{
      title: '账号服务',
      name: 'accountService',
      imgSrc: require('./images/accountService.png'),
      component: shallowRef(AccountService),
      desc: '对账号相关状态进行重置、修改等操作',
      role: 'AccountService'
    }, {
      title: '玩家账户查询',
      name: 'accountSearch',
      imgSrc: require('./images/account.png'),
      component: shallowRef(AccountSearch),
      desc: '查询玩家账户信息',
      role: 'accountSearch'
    }, {
      title: '订单查询',
      name: 'orderSearch',
      imgSrc: require('./images/orderSearch.png'),
      component: shallowRef(OrderSearch),
      desc: '查询订单数据',
      role: 'orderSearch'
    }, {
      title: '退款查询',
      name: 'refundSearch',
      imgSrc: require('./images/line-refund.png'),
      component: shallowRef(RefundSearch),
      desc: '查询退款数据',
      role: 'refundSearch'
    }, {
      title: 'GS绑定',
      name: 'gsBind',
      imgSrc: require('./images/gsBindIcon.png'),
      component: shallowRef(GSBind),
      desc: '通过绑定玩家账号与渠道的结算方式为GS服务提供支持',
      role: 'GSBind'
    }]
  }, {
    label: '渠道分包管理',
    value: 'packageManage',
    role: 'channelDistribution',
    items: [{
      title: '官方分包',
      name: 'officialPackage',
      imgSrc: require('./images/official.png'),
      component: shallowRef(officialSubPackage),
      desc: '以CDN形式对官方包进行二次分发',
      role: 'officialDistribution'
    }, {
      title: '分发渠道管理',
      name: 'distributeChannel',
      imgSrc: require('./images/distribute.png'),
      component: shallowRef(ChannelManage),
      desc: '管理不同合作的分发渠道，包括分成方式、分成比例、收款信息',
      role: 'distributionChannel'
    }, {
      title: '官方包管理',
      name: 'officialPackageVersion',
      imgSrc: require('./images/officialManage.png'),
      component: shallowRef(OfficialPackageManage),
      desc: '管理不同版本的母包',
      role: 'officialPackages'
    }]
  }];
  const miniAppToolTbas = [{
    label: '登录支付配置',
    value: 'base',
    role: 'login&pay',
    items: [{
      title: '小游戏切支付',
      name: 'miniappPay',
      imgSrc: require('./images/changepay.png'),
      component: shallowRef(MiniPay),
      desc: '微信小游戏支付方式切换配置',
      role: 'miniappPay'
    }]
  }];
  const handleFilter = item => {
    currentTool.value = item.value;
  };
  const items = computed(() => {
    const _tabs = gameType.value === 'miniapp' && btnPermissions.includes('miniappPay') ? miniAppToolTbas : toolTabs;
    return _tabs.filter(item => {
      if (currentTool.value === 'all' && item.value !== 'all' && btnPermissions.includes(item.role)) {
        return true;
      } else if (item.value === currentTool.value && item.value !== 'all' && btnPermissions.includes(item.role)) {
        return true;
      }
    });
  });
  const filterPermission = computed(() => {
    const _tabs = gameType.value === 'miniapp' && btnPermissions.includes('miniappPay') ? miniAppToolTbas : toolTabs;
    return _tabs.filter(item => {
      if (disabledInternal.includes(process.env.VUE_APP_PLAT) && item.role === 'internalChannel') {
        return false;
      }
      if (btnPermissions.includes(item.role)) {
        return true;
      }
    });
  });
  watch(() => gameType.value, val => {
    currentTool.value = 'base';
  });
  return {
    currentTool,
    toolTabs: filterPermission,
    handleFilter,
    items
  };
};